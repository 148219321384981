import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { Col, Row } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs'
import CalendarModal from './CalendarModal';
import { getContentWithActionAndPlantId, getContentWithAction, postContentWithAction, postContentWithAction_, getContentWithActionAndLanguage } from '../../apiConnect';
import { getSelectedPlot, formatGroupLabel, customStyles, getSelectedLang } from '../../common';
import { deleteRowStyle, deleteGrowthStage } from '../ComponentStyles/ButtonStyles';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import { useAlert } from 'react-alert'
import { valueMax, valueMaxObservation, dayJsFormatDate, round } from '../../commonFunctions';
import AppContext from '../../AppContext';
import MapModal from './MapModal';
import { featuresReader, retrieveWgsCoordinates } from '../../Map/commonMapFunctions';
import * as GardenPlants from '../../Constants/GardenPlantsConstants.js';

import '../../css/general.css'
import '../../css/plotOperation.css'

const PlantProtectionModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [calendarShow, setCalendarShow] = useState(false);
    const [mapShow, setMapShow] = useState(false);


    const [observes, setObserves] = useState([]);
    const [fertilizers, setFertilizers] = useState([]);
    const [growthStages, setGrowthStages] = useState([]);
    const [pesticides, setPesticides] = useState([]);
    const [observationMeasures, setObservationMeasures] = useState([]);

    const [growthInfo, setGrowthInfo] = useState(0);
    const [newSelectedGrowthInfo, setNewSelectedGrowthInfo] = useState(0);
    const [growthInfoSelected, setGrowthInfoSelected] = useState(0);
    const [observationRows, setObservationRows] = useState([]);
    const [pesticideRows, setPesticideRows] = useState([]);
    const [fertilizerRows, setFertilizerRows] = useState([]);

    const [selectedGrowthStage, setSelectedGrowthStage] = useState(null);
    const [pesticideArea, setPesticideArea] = useState(1);
    const [date, setDate] = useState(dayjs().format());
    const [updatePlantProtections, setUpdatePP] = useState(0);
    const [multiSelect, setMultiSelect] = useState(false);
    const [validate, setValidation] = useState(false);
    const [visibility, setVisibility] = useState(false);

    const [disableGrowthStage, setDisableGrowthStage] = useState(true);
    const [gardenPlant, setGardenPlant] = useState(false);
    const [disableSaveButton, setDisableSaveButton] = useState(true);

    //EXTRA INFO FIELDS
    const [startTime, setStartTime] = useState(null);
    const [temperature, setTemperature] = useState(null);
    const [waterLha, setWaterLha] = useState(null);
    const [wind, setWind] = useState(null);
    const textt = (t('Select'));
    const windDirectionList = [
        { label: t('North'), value: 1 },
        { label: t('NorthEast'), value: 2 },
        { label: t('East'), value: 3 },
        { label: t('SouthEast'), value: 4 },
        { label: t('South'), value: 5 },
        { label: t('SouthWest'), value: 6 },
        { label: t('West'), value: 7 },
        { label: t('NorthWest'), value: 8 }];
    const [windDirection, setWindDirection] = useState(null);
    const [safeDistanceWind, setSafeDistanceWind] = useState(null);
    const [safeDistanceWindTarget, setSafeDistanceWindTarget] = useState(null);
    const [safeDistanceSoil, setSafeDistanceSoil] = useState(null);
    const [safeDistanceSoilTarget, setSafeDistanceSoilTarget] = useState(null);
    const [sprayBoom, setSprayBoom] = useState(null);
    const [serviceLocation, setServiceLocation] = useState(null);
    const [maker, setMaker] = useState(null);

    const [mapModalParameters, setMapModalParameters] = useState(null);
    const [coordinates, setCoordinates] = useState(null);
    const [coordinatesLoading, setCoordinatesLoading] = useState(false);
    const [buttonWriteShow, setButtonWriteShow] = useState(false); // allowed save btn disabled

    let selectedPlot = useRef(getSelectedPlot());
    const alert = useAlert();
    let plantId = undefined;
    let plotId = undefined;
    let plotArea = undefined;

    const OBSERVATION_AMOUNT_DISABLED = [7, 14, 15, 16, 17];

    const OBSERVATION_AMOUNT_LITTLE = {
        value: 15,
        mode: 15,
        label: t('AmountLittle')
    };

    const OBSERVATION_AMOUNT_MODERATE = {
        value: 16,
        mode: 16,
        label: t('AmountModerate')
    };

    const OBSERVATION_AMOUNT_ALOT = {
        value: 17,
        mode: 17,
        label: t('AmountAlot')
    };

    if (selectedPlot?.current) {
        plotId = selectedPlot.current?.apNumber ? selectedPlot.current.apNumber : 0;
        plotArea = selectedPlot.current?.area ? selectedPlot.current.area : 0;
    }
    else if (props.selectEvent?.selectedPlots) {
        plantId = props.selectEvent?.selectedPlots[0]?.plantId;
        plotId = 0;
        plotArea = 0;
    }
    if (props.mapEvent?.plotData) {
        plantId = props.mapEvent.plotData.plantId;
        plotId = props.mapEvent.plotData.apNumber;
        plotArea = props.mapEvent.plotData.area;
    }

    useEffect(() => {
        Promise.all([
            //ALL FERTILIZERS
            getContentWithAction(auth.getAccessToken(), 'resource', 'fertilizers', {}),
            //PREVIOUS FERTILIZERS
            getContentWithAction(auth.getAccessToken(), 'resource', 'previousfertilizers', {}),
            //OBSERVATIONS
            getContentWithAction(auth.getAccessToken(), 'resource', 'observe', {}),
            //PREVIOUS OBSERVATIONS
            getContentWithAction(auth.getAccessToken(), 'resource', 'previousObserves', {}),
            //GROWTH STAGES           
            !plantId ? getContentWithActionAndPlantId(auth.getAccessToken(), 'resource', 'growthStage', {})
                : getContentWithAction(auth.getAccessToken(), 'resource', 'growthStage', { plantId: plantId }),
            //PESTICIDES
            getContentWithAction(auth.getAccessToken(), 'resource', 'pesticides', {}),
            //OBSERVATION MEASURES
            getContentWithActionAndLanguage(auth.getAccessToken(), 'resource', 'observationMeasure', {}),
            // session
            getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {})
        ]).then(([allFertilizers, previousfertilizers, observations, previousObservations, growthStages, pesticides, observationMeasures, sessionResult]) => {

            // pest fertilizer, only specials.
            allFertilizers = allFertilizers.filter(m => {
                if (m.particleSpecial > 0) return m;
            });
            previousfertilizers = previousfertilizers.filter(p => {
                if (allFertilizers.some(a => a.product === p.product)) return true;

                return false;
            });

            setFertilizers(handleArraySorting(previousfertilizers, allFertilizers));
            if (growthStages)
                setGrowthStages(growthStages.map(p => (
                !p.value.includes('T') && Number(p.value).toString().length < 4 ? { ...p, label: p.label.substring(p.label.indexOf("-") + 1).trim(), type: 'selectableHeader' } : p)));

            setObserves(handleArraySorting(previousObservations, observations));

            //PESTICIDES filtering for correct default scrolling position in dropdown list
            var prevPesticides = pesticides.filter(p => p.farmUsed === 1).map(v => {
                const { farmUsed, ...newObject } = v;
                return newObject;
            });
            var allPesticides = pesticides.map(p => {
                const { farmUsed, ...newObject } = p;
                return newObject;

            });
            //
            setPesticides(handleArraySorting(prevPesticides, allPesticides));
            if (observationMeasures)
                setObservationMeasures(handleObservationUnitSorting(observationMeasures));
            //observationMeasures.push(OBSERVATION_AMOUNT_LITTLE, OBSERVATION_AMOUNT_MODERATE, OBSERVATION_AMOUNT_ALOT);

            setButtonWriteShow(sessionResult);
        });
    }, []);

    useEffect(() => {
        formatFields();
        plantTypeDetector();

        if (props.mapEvent && props.mapEvent.plotData) {
            setCoordinates(props.mapEvent.coordinates);
            if (!props.mapEvent.new) {
                postContentWithAction(auth.getAccessToken(), 'plotOperation', 'getPlantProtectionOperations', { models: [{ plotId: props.mapEvent.plotData.apNumber }], lang: getSelectedLang() }, (protections) => {
                    if (protections.length > 0) {
                        let filteredObservationItem = protections.find(x => x.observations.some(v => v.featureGeometryId === props.mapEvent.geometryID));
                        responseHandler(filteredObservationItem);
                        if (filteredObservationItem.observations.length > 0) {
                            setVisibility(filteredObservationItem.observations[0].visibility);

                        }
                    }
                }).catch((c) => { });
            }
            else
                responseHandler(null);

            // Add this to prevent a bug where modal closes when clicking a map feature
            // this happens because click happens an extra time and calls props.onHide
            // TODO: Fix the bug later?
            setTimeout(() => { props.allowHide() }, 250);
        }

        if (props.selectEvent) {            
            props.selectEvent.selectedElement
                ? responseHandler(props.selectEvent.selectedElement)
                : responseHandler(null);
            if (!props.selectEvent.selectedElement) return;

            let obs = props.selectEvent.selectedElement.observations;
            if (obs && obs.length && obs[0].featureGeometryId) {

                getContentWithAction(auth.getAccessToken(), 'map', 'mapFeatureWithId', { id: obs[0].featureGeometryId })
                    .then((data) => {
                        var features = featuresReader(data);
                        // find just in case, should be only one element
                        const observationFeature = features.find(f => f.getProperties().FeatureGeometryId === obs[0].featureGeometryId);
                        if (observationFeature) {
                            var coordinates = observationFeature.getProperties().geometry.flatCoordinates;
                            const wgscoord = retrieveWgsCoordinates(coordinates);
                            /*setCoordinates({ longitude: coordinates[0], latitude: coordinates[1], wgs: wgscoord });*/
                            setCoordinates({ longitude: obs[0].longitude, latitude: obs[0].latitude, wgs: wgscoord });
                            setVisibility(observationFeature.getProperties().Visibility);
                        }
                        setCoordinatesLoading(false);
                    });

            }
            else
                setCoordinatesLoading(false);
        }
    }, [props.selectEvent, props.mapEvent]);

    useEffect(() => {
        /* Update function if needed? */
    }, [updatePlantProtections]);

    useEffect(() => {
        if (growthStages && growthStages.length) {
            setDisableGrowthStage(false);
            let found = growthStages.find(v => v.value === selectedGrowthStage);
            if (found) {
                setGrowthInfoSelected(found);
            }
            else {
                setGrowthInfoSelected({ label: (t('SelectGrowthStage')), value: null })
            }
        }
    }, [growthStages, selectedGrowthStage]);


    const handleArraySorting = (previousList, allList) => {
        const completeList = [
            { label: t('Previously_Used'), options: previousList },
            { label: t('All'), options: allList }
        ];
        return completeList;
    };

    const handleObservationUnitSorting = (observationUnits) => {
        const sortedList = [...observationUnits];
        sortedList.unshift(OBSERVATION_AMOUNT_LITTLE, OBSERVATION_AMOUNT_MODERATE, OBSERVATION_AMOUNT_ALOT);
        //sortedList.some((item, i, arr) => item.value == 14 && arr.unshift(item));
        sortedList.some((item, idx) =>
            item.value === 14 &&
            sortedList.unshift(
                // remove the found item, in-place (by index with splice), 
                // returns an array of a single item removed
                sortedList.splice(idx, 1)[0]
            )
        )
        //sortedList.unshift(sortedList.pop());
        return sortedList;
    };

    const responseHandler = (data) => {
        if (!data) {
            setMultiSelect(props.selectEvent?.selectedPlots ? true : false);         

            setGrowthInfo(formatGrowthInfo());
            setPesticideArea(props.selectEvent?.selectedPlots ? props.selectEvent.selectedPlots.reduce((total, currentItem) => total = total + currentItem.area, 0) : plotArea ? plotArea : 1);

            // Add one empty row for inputs
            addEmptyObservation(plotId ? plotId : 0);
            if (props.selectEvent) {
                addEmptyPesticide();
            }           

            return;
        }
        // Only observation data
        if (!data.growthInformation.date && data.growthInformation.area === 0) {
            setGrowthInfo(formatGrowthInfo());
            setPesticideArea(props.selectEvent?.selectedPlots ? props.selectEvent.selectedPlots.reduce((total, currentItem) => total = total + currentItem.area, 0) : plotArea ? plotArea : 1);
            setObservationRows(ArrayCopier(data.observations));
            if (props.selectEvent) {
                addEmptyPesticide();
            }  
            return;
        }

        if (data.observations.length === 0)
            addEmptyObservation(multiSelect ? 0 : plotId);
        else {
            setObservationRows(ArrayCopier(data.observations));
        }

        if (data.pesticides.length === 0)
            addEmptyPesticide();
        else {
            setPesticideRows(ArrayCopier(data.pesticides));
        }

        setFertilizerRows(ArrayCopier(data.fertilizings));

        setGrowthInfo(data.growthInformation);
        setSelectedGrowthStage(data.growthInformation.growthStage);

        if (data.growthInformation.date)
            setDate(data.growthInformation.date);

        if (data.growthInformation) {
            ///EXTRA FIELDS

            if (data.growthInformation.waterArea)
                setWaterLha(data.growthInformation.waterArea);
            if (data.growthInformation.windDirection)
                setWindDirection(windDirectionList.find(item => item.value === data.growthInformation.windDirection));
            if (data.growthInformation.wind)
                setWind(data.growthInformation.wind);
            if (data.growthInformation.startHour)
                setStartTime(data.growthInformation.startHour);
            if (data.growthInformation.temperature)
                setTemperature(data.growthInformation.temperature);
            if (data.growthInformation.safeDistanceSoil)
                setSafeDistanceSoil(data.growthInformation.safeDistanceSoil);
            if (data.growthInformation.safeDistanceSoilTarget)
                setSafeDistanceSoilTarget(data.growthInformation.safeDistanceSoilTarget);
            if (data.growthInformation.safeDistanceWind)
                setSafeDistanceWind(data.growthInformation.safeDistanceWind);
            if (data.growthInformation.safeDistanceWindTarget)
                setSafeDistanceWindTarget(data.growthInformation.safeDistanceWindTarget);
            if (data.growthInformation.sprayBoom)
                setSprayBoom(data.growthInformation.sprayBoom);
            if (data.growthInformation.maker)
                setMaker(data.growthInformation.maker);
            if (data.growthInformation.serviceLocation)
                setServiceLocation(data.growthInformation.serviceLocation);
        }
        let sprayArea = data.growthInformation ? data.growthInformation.area : plotArea;
        setPesticideArea(sprayArea);
    }

    const formatFields = () => {
        setGrowthInfo(0);
        setNewSelectedGrowthInfo(0);
        setObservationRows([]);
        setPesticideRows([]);
        setFertilizerRows([]);
        setPesticideArea(0);
        setSelectedGrowthStage(null);
        setCoordinates(null);
    }

    const formatGrowthInfo = () => {

        let newGrowthInfo = {
            saveSpray: true,
            visibilityChanged: false,
            pesticideId: 0,
            plotId: props.selectEvent?.selectedPlots ? 0 : plotId,
            area: props.selectEvent?.selectedPlots ? 0 : plotArea,
            waterArea: 0,
            growthStage: '',
            date: dayjs(date).format("YYYY-MM-DD"),
            plannedDate: '',
            windDirection: 0,
            wind: 0,
            startHour: 0,
            temperature: 0,
            safeDistanceSoil: 0,
            safeDistanceSoilTarget: '',
            safeDistanceWind: 0,
            safeDistanceWindTarget: '',
            sprayBoom: '',
            maker: '',
            growthStageName: '',
            serviceLocation: ''
        };
        return newGrowthInfo;
    }


    const plantTypeDetector = () => {
        if (selectedPlot?.current?.plantId) {
            if (GardenPlants.gardenPlantTypes.includes(selectedPlot.current.plantId.toString().substring(0, 2))) {
                setGardenPlant(true);
            }
        }
        if (props?.selectEvent?.selectedPlots) {
            if (GardenPlants.gardenPlantTypes.includes(props.selectEvent.selectedPlots[0].plantId.toString().substring(0, 2))) {
                setGardenPlant(true);
            }
        }
        if (props?.mapEvent?.plotData?.plantId) {
            if (GardenPlants.gardenPlantTypes.includes(props.mapEvent.plotData.plantId.toString().substring(0, 2))) {
                setGardenPlant(true);
            }
        }
    }

    // Required for the information not to be passed to props without saving
    const ArrayCopier = (array) => {
        let newArray = [];
        array.forEach(v => { // Copy values to the new array
            let copyData = { ...v };
            newArray.push(copyData);
        });
        return newArray;
    }

    const updateArrayValue = (index, value, array) => {
        let newArray = [];
        array.forEach(v => { // Copy values to the new array
            let copyData = { ...v };
            newArray.push(copyData);
        });
        newArray[index] = value;
        return newArray;
    }

    const updateGrowthInfoDate = (value) => {
        var updatedGrowthInfo = { ...growthInfo }
        setDate(value);
        updatedGrowthInfo.date = value;
        updatedGrowthInfo.saveSpray = true;
        setGrowthInfo(updatedGrowthInfo);

        var updatedGrowthInfo2 = { ...newSelectedGrowthInfo }
        updatedGrowthInfo2.date = value;
        setNewSelectedGrowthInfo(updatedGrowthInfo2);
        setDisableSaveButton(false);

        // update also fertilizer date
        if (fertilizerRows) {
            fertilizerRows.forEach(f => {
                f.date = value
            });
        }
    }

    const removeGrowthStage = () => {

        //Remove pesticide row from DB altogether if growthstage is only element left
        if ((!pesticideRows.length || (pesticideRows.length && pesticideRows[0].hasOwnProperty("insert"))) && (!fertilizerRows.length || (fertilizerRows.length && fertilizerRows[0].hasOwnProperty("insert"))) && (!observationRows.length || (observationRows.length && observationRows[0].hasOwnProperty("insert")))) {

            var operations = [];
            var deleteOperation = {
                pesticideId: growthInfo.pesticideId,
                plotId: growthInfo.plotId
            };
            operations.push(deleteOperation);

            postContentWithAction_(auth.getAccessToken(), 'plotOperation', 'deletePlantProtectionOperations',
                { models: operations }).then(() => {
                    if (props.selectEvent) {
                        setGrowthInfoSelected({ label: (t('SelectGrowthStage')), value: null });
                        setGrowthInfo(formatGrowthInfo());
                        setDisableSaveButton(true);
                        props.setRefresh(Date.now());
                    }
                    if (props.mapEvent) {
                        props.deleteObservation();
                    }
                });
        }
        else {
            var updatedGrowthInfo = { ...growthInfo };
            var growths = [];
            // Delete previously saved growthstage to DB
            if (newSelectedGrowthInfo === 0) {
                updatedGrowthInfo.growthStage = '';
                updatedGrowthInfo.growthStageName = '';
                growths.push(updatedGrowthInfo);
                postContentWithAction_(auth.getAccessToken(), 'plotOperation', 'deleteGrowthStage',
                    { models: growths }).then(() => {
                        setGrowthInfoSelected({ label: (t('SelectGrowthStage')), value: null });
                        setGrowthInfo(updatedGrowthInfo);
                        if (props.selectEvent) {
                            props.setRefresh(Date.now());
                        }
                    });
            }
            else {
                // Delete previously saved growthstage to DB with new one selected
                if (updatedGrowthInfo.growthStage) {
                    updatedGrowthInfo.growthStage = '';
                    updatedGrowthInfo.growthStageName = '';
                    growths.push(updatedGrowthInfo);
                    postContentWithAction_(auth.getAccessToken(), 'plotOperation', 'deleteGrowthStage',
                        { models: growths }).then(() => {
                            setGrowthInfoSelected({ label: (t('SelectGrowthStage')), value: null });
                            setGrowthInfo(updatedGrowthInfo);
                            if (props.selectEvent) {
                                props.setRefresh(Date.now());
                            }
                        });
                }
                // Empty recently selected growthstage, but not saved to DB 
                setGrowthInfoSelected({ label: (t('SelectGrowthStage')), value: null });
                setNewSelectedGrowthInfo(0);
            }
        }

    }


    const removePesticide = (pest, index) => {
        //Remove pesticide row from DB altogether if pesticide is only element left
        if (index === 0 && (!growthInfo.growthStage && !growthInfo.growthStageName) && (!fertilizerRows.length || (fertilizerRows.length && fertilizerRows[0].hasOwnProperty("insert"))) && (!observationRows.length || (observationRows.length && observationRows[0].hasOwnProperty("insert")))) {

            var operations = [];
            var deleteOperation = {
                pesticideId: growthInfo.pesticideId,
                plotId: growthInfo.plotId
            };
            operations.push(deleteOperation);

            postContentWithAction_(auth.getAccessToken(), 'plotOperation', 'deletePlantProtectionOperations',
                { models: operations }).then(() => {
                    if (props.selectEvent) {
                        clearPesticideRow(pest, index);
                        setGrowthInfo(formatGrowthInfo());
                        setDisableSaveButton(true);
                        props.setRefresh(Date.now());
                    }
                    if (props.mapEvent) {
                        props.deleteObservation();
                    }
                });
        }
        else {
            var pests = [];
            pests.push(pest);
            postContentWithAction(auth.getAccessToken(), 'plotOperation', 'deletePlantProtectionPesticide', { models: pests }, () => {
                clearPesticideRow(pest, index);
                props.setRefresh(Date.now());
            });
        }
    }

    const removeFertilizer = (fert, index) => {
        //Remove pesticide row from DB altogether if fertilizer is only element left
        if (index === 0 && (!growthInfo.growthStage && !growthInfo.growthStageName) && (!pesticideRows.length || (pesticideRows.length && pesticideRows[0].hasOwnProperty("insert"))) && (!observationRows.length || (observationRows.length && observationRows[0].hasOwnProperty("insert")))) {

            var operations = [];
            var deleteOperation = {
                pesticideId: growthInfo.pesticideId,
                plotId: growthInfo.plotId
            };
            operations.push(deleteOperation);

            postContentWithAction_(auth.getAccessToken(), 'plotOperation', 'deletePlantProtectionOperations',
                { models: operations }).then(() => {
                    if (props.selectEvent) {
                        clearFertilizerRow(fert, index);
                        setGrowthInfo(formatGrowthInfo());
                        setDisableSaveButton(true);
                        props.setRefresh(Date.now());
                    }
                    if (props.mapEvent) {
                        props.deleteObservation();
                    }
                });
        }
        else {
            var ferts = [];
            ferts.push(fert);
            postContentWithAction(auth.getAccessToken(), 'plotOperation', 'deletePlantProtectionFertilizing', { models: ferts }, () => {
                clearFertilizerRow(fert, index);
                props.setRefresh(Date.now());
            });
        }
    }

    const removeObservation = (obs, index) => {
        //Remove pesticide row from DB altogether if observation is only element left
        if (index === 0 && (!growthInfo.growthStage && !growthInfo.growthStageName) && (!pesticideRows.length || (pesticideRows.length && pesticideRows[0].hasOwnProperty("insert"))) && (!fertilizerRows.length || (fertilizerRows.length && fertilizerRows[0].hasOwnProperty("insert")))) {

            var operations = [];
            var deleteOperation = {
                pesticideId: growthInfo.pesticideId,
                plotId: growthInfo.plotId
            };
            operations.push(deleteOperation);

            postContentWithAction_(auth.getAccessToken(), 'plotOperation', 'deletePlantProtectionOperations',
                { models: operations }).then(() => {
                    if (props.selectEvent) {
                        clearObservationRow(obs, index);
                        clearCoordinates();
                        setGrowthInfo(formatGrowthInfo());
                        setDisableSaveButton(true);
                        props.setRefresh(Date.now());
                    }
                    if (props.mapEvent) {
                        props.deleteObservation();
                    }
                });
        }
        else {
            postContentWithAction(auth.getAccessToken(), 'plotOperation', 'deleteObservation', obs, () => {
                clearObservationRow(obs, index);
                clearCoordinates();
                props.setRefresh(Date.now());
            });
        }
    }

    const removeCoordinates = () => {
        const dataModel = {
            models: observationRows
        };
        postContentWithAction(auth.getAccessToken(), 'plotOperation', 'deleteCoordinates', dataModel, () => {
            clearCoordinates();
            props.setRefresh(Date.now());
        });
    }

    const clearFertilizerRow = (fert, index) => {
        let newFertRows = [...fertilizerRows];
        newFertRows.splice(index, 1);
        setFertilizerRows(newFertRows);
        if (index === 0 && (!growthInfo.growthStage && !growthInfo.growthStageName && !newSelectedGrowthInfo.growthStage && !newSelectedGrowthInfo.growthStageName) && !pesticideRows.length && !observationRows.length) {
            setDisableSaveButton(true);
        }
    }

    const clearPesticideRow = (pest, index) => {
        let newPestRows = [...pesticideRows];
        newPestRows.splice(index, 1);
        setPesticideRows(newPestRows);
        if (index === 0 && (!growthInfo.growthStage && !growthInfo.growthStageName && !newSelectedGrowthInfo.growthStage && !newSelectedGrowthInfo.growthStageName) && !fertilizerRows.length && !observationRows.length) {
            setDisableSaveButton(true);
        }
    }

    const clearObservationRow = (obs, index) => {
        let newSelectedObs = [...observationRows];
        newSelectedObs.splice(index, 1);
        setObservationRows(newSelectedObs);
        if (index === 0 && (!growthInfo.growthStage && !growthInfo.growthStageName && !newSelectedGrowthInfo.growthStage && !newSelectedGrowthInfo.growthStageName) && !pesticideRows.length && !fertilizerRows.length) {
            setDisableSaveButton(true);
        }
    }

    const clearCoordinates = () => {
        setCoordinates(null);
    }

    const addEmptyObservation = (plotNumber) => {
        let selectedObs = [...observationRows];
        let newObservation = {
            observationId: -1,
            plotId: plotNumber,
            mode: 0,
            product: '',
            insert: true,
            density: 0,
            date: dayjs(Date.now()).format("YYYY-MM-DD"),
            plannedDate: '',
            pesticideId: pesticideRows[0] ? pesticideRows[0].pesticideId : 0,
            productName: ''
        };

        selectedObs.push(newObservation);

        setObservationRows(selectedObs);      
    }

    const addEmptyPesticide = () => {
        let latestElement = pesticideRows[pesticideRows.length - 1];
        let selectedPests = [...pesticideRows];
        let newPesticide = {
            pesticideId: latestElement ? latestElement.pesticideId : 0,
            product: '',
            insert: true,
            amount: 0,
            unitType: latestElement ? latestElement.unitType : 0,
            area: pesticideArea,
            withdrawal: latestElement ? latestElement.withdrawal : 0,
            productName: ''
        };

        selectedPests.push(newPesticide);

        setPesticideRows(selectedPests);       
    }

    const addEmptyFertilizer = () => {
        let latestElement = fertilizerRows[fertilizerRows.length - 1];
        let selectedFerts = [...fertilizerRows];
        let newFertilizer = {
            amount: 0,
            area: pesticideArea,
            date: dayjs(Date.now()).format("YYYY-MM-DD"),
            description: '',
            product: '',
            insert: true,
            fertilizingId: undefined,
            pesticideId: latestElement ? latestElement.pesticideId : 0,
            plannedDate: latestElement ? latestElement.plannedDate : '',
            plotId: props.selectEvent?.selectedPlots ? 0 : plotId,
            productName: '',
            unitType: latestElement ? latestElement.unitType : 0
        };

        selectedFerts.push(newFertilizer);

        setFertilizerRows(selectedFerts);        
    }

    const validateOperation = () => {
        //validate
        let valid = true;
        pesticideRows.forEach(o => {
            if (!o.amount || !o.product) valid = false;
        });
        fertilizerRows.forEach(o => {
            if (!o.amount || !o.product) valid = false;
        });
        observationRows.forEach(o => {          
            if (!o.product) {
                valid = false;
            }
            if (o.product && !o.density && o.mode !== 7 && o.mode !== 14 && o.mode !== 15 && o.mode !== 16 && o.mode !== 17) {
                valid = false;
            }

        });

        if (!pesticideRows.length && !fertilizerRows.length && !observationRows.length && !growthInfo.growthStage) valid = false;

        if (!pesticideRows.length && !fertilizerRows.length && !observationRows.length) {

            valid = false;
            alert.show(t('ErrorOnlyGrowthstage'), { type: 'info', style: "alternative", timeout: 0 });
        }

        if (selectedPlot.current) {
            if (selectedPlot.current.area < pesticideArea) valid = false;
        }

        return valid;
    }

    const updateObservationCoordinates = (coord) => {
        observationRows.forEach(row => {
            row.longitude = coord.longitude;
            row.latitude = coord.latitude;
            row.updated = true;
        });
        setDisableSaveButton(false);
    }

    const saveMultiSelectPlantProtection = () => {
        if (!validateOperation()) return;

        // Filtering inserts
        let filteredFertRows = fertilizerRows.filter(row => row.insert);
        let filteredPestRows = pesticideRows.filter(row => row.insert);
        let filteredObsRows = observationRows.filter(row => row.insert);
        let operations = [];

        props.selectEvent.selectedPlots.forEach(plot => {
            var newFertRows = [];
            var newPestRows = [];
            var newObsRows = [];

            // Deep copying rows (assigning doesn't work)
            filteredFertRows.forEach(row => {
                row.plotId = plot.apNumber;
                newFertRows.push({ ...row }); // Copy
            });
            filteredPestRows.forEach(row => {
                row.plotId = plot.apNumber;
                newPestRows.push({ ...row }); // Copy
            });
            filteredObsRows.forEach(row => {
                row.plotId = plot.apNumber;
                newObsRows.push({ ...row }); // Copy
            });

            var newGrowthInfo = growthInfoEnlargement(growthInfo, plot);

            let updateOperations = {
                pesticideId: newGrowthInfo.pesticideId,
                growthInformation: newGrowthInfo,
                fertilizings: [],
                pesticides: [],
                observations: []
            }

            let insertOperations = {
                pesticideId: 0,
                growthInformation: newGrowthInfo,
                fertilizings: newFertRows,
                pesticides: newPestRows,
                observations: newObsRows
            };

            let protectionOperation = {
                growthInfo: newGrowthInfo,
                inserts: insertOperations,
                updates: updateOperations
            };

            let protectionSaveModel = {
                plotId: plot.apNumber,
                protectionOperations: [protectionOperation]
            };

            operations.push(protectionSaveModel);
        });

        postContentWithAction(auth.getAccessToken(), 'plotOperation', 'savePlantProtectionOperations',
            { models: operations }, () => {
                props.setRefresh(Date.now());
                alert.show(t('SavedSuccesfully'), { type: 'success' });
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
            });
        props.onHide();
    }

    const savePlantProtection = () => {
        if (!validateOperation()) return;

        let updatedFertRows = fertilizerRows.filter(row => row.updated && !row.insert);
        let updatedPestRows = pesticideRows.filter(row => row.updated && !row.insert);
        let updatedObsRows = observationRows.filter(row => row.updated && !row.insert);

        let newFertRows = fertilizerRows.filter(row => row.insert);
        let newPestRows = pesticideRows.filter(row => row.insert);
         let newObsRows = observationRows.filter(row => row.insert);
       /* let newObsRows = observationRows.filter(row => row.insert || (!row.insert && !row.updated && row.observationId > -1));*/

        var newGrowthInfo = growthInfoEnlargement(growthInfo);

        updatedObsRows.forEach(row => {
            if (coordinates) {
                row.longitude = coordinates.longitude;
                row.latitude = coordinates.latitude;
            }
        });

        newObsRows.forEach(row => {
            if (coordinates) {
                row.longitude = coordinates.longitude;
                row.latitude = coordinates.latitude;
            }
        });

        let updateOperations = {
            pesticideId: newGrowthInfo.pesticideId,
            growthInformation: null, // used only in get method
            fertilizings: updatedFertRows,
            pesticides: updatedPestRows,
            observations: updatedObsRows
        }

        let insertOperations = {
            pesticideId: newGrowthInfo.pesticideId,
            growthInformation: newGrowthInfo, // used only in get method
            fertilizings: newFertRows,
            pesticides: newPestRows,
            observations: newObsRows
        };

        let protectionOperation = {
            growthInfo: newGrowthInfo,
            inserts: insertOperations,
            updates: updateOperations
        };
        let protectionSaveModel = {
            plotId: plotId,
            protectionOperations: [protectionOperation]
        };
        let operations = [];
        operations.push(protectionSaveModel);

        postContentWithAction_(auth.getAccessToken(), 'plotOperation', 'savePlantProtectionOperations',
            { models: operations }).then((data) => {
                if (props.mapEvent) {
                    props.saveObservation(data);
                }
                else {
                    props.setRefresh(Date.now());
                }
                alert.show(t('SavedSuccesfully'), { type: 'success' });
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
            });
        props.onHide();
    }

    const growthInfoEnlargement = (growthInfo, plot) => {
        if (growthInfo !== 0) {
            var newGrowthInfo = { ...growthInfo };

            if (newSelectedGrowthInfo !== 0) {
                newGrowthInfo.growthStage = newSelectedGrowthInfo.growthStage;
                newGrowthInfo.growthStageName = newSelectedGrowthInfo.growthStageName;
                newGrowthInfo.date = newSelectedGrowthInfo.date;
                newGrowthInfo.saveSpray = true;
            }

            if (multiSelect && plot !== undefined) {
                newGrowthInfo.area = plot.area;
                newGrowthInfo.plotId = plot.apNumber;
            }

            if (pesticideArea && !multiSelect)
                newGrowthInfo.area = pesticideArea;
            if (waterLha)
                newGrowthInfo.waterArea = waterLha;
            if (windDirection)
                newGrowthInfo.windDirection = windDirection.value;
            if (wind)
                newGrowthInfo.wind = wind;
            if (startTime)
                newGrowthInfo.startHour = startTime;
            if (temperature)
                newGrowthInfo.temperature = temperature;
            if (safeDistanceSoil)
                newGrowthInfo.safeDistanceSoil = safeDistanceSoil;
            if (safeDistanceSoilTarget)
                newGrowthInfo.safeDistanceSoilTarget = safeDistanceSoilTarget;
            if (safeDistanceWind)
                newGrowthInfo.safeDistanceWind = safeDistanceWind;
            if (safeDistanceWindTarget)
                newGrowthInfo.safeDistanceWindTarget = safeDistanceWindTarget;
            if (sprayBoom)
                newGrowthInfo.sprayBoom = sprayBoom;
            if (maker)
                newGrowthInfo.maker = maker;
            if (serviceLocation)
                newGrowthInfo.serviceLocation = serviceLocation;

            return newGrowthInfo;
        }
    }

    const setObservationsToUpdate = (visValue) => {
        // Set update to true for all observation rows
        observationRows.forEach((obs, i) => {
            let newValue = obs;
            newValue.updated = true;
            newValue.visibility = visValue;
            let updatedSelections = updateArrayValue(i, newValue, observationRows);
            setObservationRows(updatedSelections);
        });

        var updatedGrowthInfo = { ...growthInfo }
        updatedGrowthInfo.visibilityChanged = true;
        setGrowthInfo(updatedGrowthInfo);
        setDisableSaveButton(false);
        setUpdatePP(Date.now());
    }

    const growthUpdate = () => {
        if (growthInfo.pesticideId !== 0) {
            var update = growthInfo;
            update.saveSpray = true;
            setGrowthInfo(update);                
        }
        setDisableSaveButton(false);
    };

    const updateFertilizingAreas = (area) => {
        fertilizerRows.forEach((f, i) => {
            let newRowData = fertilizerRows[i];
            newRowData.area = area;

            let updatedRows = updateArrayValue(i, newRowData, fertilizerRows);
            setFertilizerRows(updatedRows);
        });
    }

    const deletePlantProtection = () => {
        var operations = [];
        var deleteOperation = {
            pesticideId: growthInfo.pesticideId,
            plotId: growthInfo.plotId
        };
        operations.push(deleteOperation);

        postContentWithAction_(auth.getAccessToken(), 'plotOperation', 'deletePlantProtectionOperations',
            { models: operations }).then(() => {
                if (props.selectEvent) {
                    props.onHide();
                    props.setRefresh(Date.now());
                }
                if (props.mapEvent) {
                    props.deleteObservation();
                }
            });
    }

    const buttonTypeForGrowthStageDeletion = () => {
        if (newSelectedGrowthInfo && growthInfoSelected && (!growthInfoSelected.value || growthInfoSelected.value) && growthInfo.growthStage === "") {
            return 'X';
        }
        if (newSelectedGrowthInfo && growthInfoSelected && growthInfoSelected.value && growthInfo.growthStage !== "") {
            return <Icon icon={ICONS.DELETE} color='black' size='22' />;
        }
        if (newSelectedGrowthInfo === 0 && growthInfoSelected && !growthInfoSelected.value) {
            return 'X';
        }
        else {
            return < Icon icon={ICONS.DELETE} color='black' size='22' />;
        }

    }

    const rowAdditionHandler = (latestElement) => {

        let valid = true;

        if (latestElement && !latestElement.product) valid = false;

        if (latestElement && latestElement.product && latestElement.mode !== undefined && latestElement.mode === 0) valid = false;

        if (latestElement && latestElement.product && latestElement.density !== undefined && latestElement.density === 0 &&
            latestElement.mode !== undefined && !OBSERVATION_AMOUNT_DISABLED.find(m => m === latestElement.mode)) valid = false;

        if (latestElement && latestElement.product && latestElement.amount !== undefined && latestElement.amount === 0 && !latestElement.amountArea) valid = false;

        validationExtension(valid);
        return valid;
    }

    const validationExtension = (validated) => {
        if (validated === false) {
            setValidation(true);
            validateOperation();
        }
        if (validated === true) {
            setValidation(false);
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('PlantProtection')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={mapShow ? "modal-content-hidden" : ""}>
                    <Form>
                        <Form.Group controlId="formGrowthStage">
                            <Form.Label className="text-dark">{t('PlantProtection_GrowthStage')}</Form.Label>
                            <Row className="growth-stage" >
                                <Col xs={10}>
                                    <Select
                                        className="text-dark"
                                        isDisabled={disableGrowthStage}
                                        value={growthInfoSelected}
                                        onChange={(data) => {
                                            setSelectedGrowthStage(data.value);
                                            const newGrowthinfo = {
                                                growthStage: data.value,
                                                growthStageName: data.label,
                                                date: dayjs(date).format("YYYY-MM-DD")
                                            }
                                            setNewSelectedGrowthInfo(newGrowthinfo);
                                            setDisableSaveButton(false);
                                        }}
                                        options={growthStages}
                                        styles={customStyles}
                                    />
                                </Col>
                                <Col xs={1}>
                                    <Button variant="danger" style={deleteGrowthStage} disabled={growthInfoSelected && growthInfoSelected.value ? false : true} onClick={(e) => {
                                        e.preventDefault();
                                        removeGrowthStage();
                                    }} type="text">{buttonTypeForGrowthStageDeletion()}</Button>

                                </Col>
                            </Row>
                        </Form.Group>

                        <Form.Group controlId="formObserve">
                            <Form.Label className="text-dark">{t('Observations')}</Form.Label>
                            {(observationRows) ? observationRows.map((p, i) =>
                                /* ----------- OBSERVATIONS ----------- */
                                <div key={i + p.pesticideId + p.product}>
                                    <div>
                                        <Select
                                            className={validate && !observationRows[i].product ? `text-dark invalid-border` : `text-dark`}
                                            value={observes.length && observationRows[i]
                                                ? observes[1].options.find(v => v.value === observationRows[i].product) : { label: t('SelectObservation'), value: null }}
                                            onChange={(data) => {
                                                let newValue = observationRows[i];
                                                newValue.product = data.product;
                                                newValue.productName = data.label;
                                                newValue.updated = true;
                                                // Update selected observation value
                                                let updatedSelections = updateArrayValue(i, newValue, observationRows);
                                                setObservationRows(updatedSelections);
                                                setDisableSaveButton(false);
                                                setUpdatePP(Date.now());
                                            }}
                                            options={observes}
                                            formatGroupLabel={formatGroupLabel}
                                        />
                                    </div>

                                    {/*<div className="inputs-separate-row">*/}
                                    <div className="altInputStyle">
                                        <Form.Label className="text-dark">{t('UnitAmount')}</Form.Label>
                                        <NumberFormat
                                            /* className="form-control"*/
                                            className={validate && observationRows[i].density === 0 && (observationRows[i].mode === 0 || !OBSERVATION_AMOUNT_DISABLED.find(m => m === observationRows[i].mode)) ? "form-control invalid-border" : "form-control"}
                                            disabled={OBSERVATION_AMOUNT_DISABLED.find(m => m === observationRows[i].mode)}
                                            onValueChange={value => {
                                                if (value.floatValue) {
                                                    let newRowData = observationRows[i];
                                                    newRowData.density = value.floatValue;
                                                    newRowData.updated = true;
                                                    let updatedRows = updateArrayValue(i, newRowData, observationRows);
                                                    setObservationRows(updatedRows);
                                                    setDisableSaveButton(false);
                                                    setUpdatePP(Date.now());
                                                }
                                            }}
                                            value={OBSERVATION_AMOUNT_DISABLED.find(m => m === observationRows[i].mode) ? '' : observationRows[i].density ? observationRows[i].density : ''}
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            displayType={'input'}
                                            thousandSeparator={' '}
                                            isAllowed={valueMaxObservation}
                                            allowNegative={false}
                                            allowedDecimalSeparators={['.', ',']}
                                        />

                                    </div>
                                    <div className="altInputStyle">
                                        <Form.Label className="text-dark"> </Form.Label>
                                        <Select
                                            className={validate && !observationRows[i].mode ? `text-dark invalid-border observation-unit-select` : `text-dark observation-unit-select`}
                                            value={observationMeasures && observationRows[i]
                                                ? observationMeasures.find(v => v.value === observationRows[i].mode) : 0}
                                            onChange={(data) => {
                                                let newValue = observationRows[i];
                                                newValue.mode = data.mode;
                                                newValue.updated = true;
                                                let updatedSelections = updateArrayValue(i, newValue, observationRows);
                                                setObservationRows(updatedSelections);
                                                setDisableSaveButton(false);
                                                setUpdatePP(Date.now());
                                            }}
                                            options={observationMeasures}
                                            placeholder={t('Unit')}
                                        />
                                    </div>
                                    <div className="altInputStyle">
                                        {!p.insert
                                            ? <Button variant="danger" style={deleteRowStyle} onClick={(e) => {
                                                e.preventDefault();
                                                removeObservation(p, i);
                                            }} type="text"><Icon icon={ICONS.DELETE} color='black' size='22' /></Button>

                                            : <Button variant="danger" style={deleteRowStyle} onClick={(e) => {
                                                e.preventDefault();
                                                clearObservationRow(p, i);
                                            }} type="text"> X </Button>}
                                    </div>

                                </div>
                            )
                                : ''
                            }
                            <div className="row">
                                <div className="col-8 pesticide-selection-margin">
                                    <Button
                                        variant="success"
                                        onClick={(e) => {
                                            /* ----------- NEW OBSERVATION ----------- */
                                            e.preventDefault();
                                            let latestElement = observationRows[observationRows.length - 1];
                                            if (!rowAdditionHandler(latestElement)) return;

                                            addEmptyObservation(multiSelect ? 0 : plotId);
                                        }} type="text">{t('Observation_AddObservation')}</Button>
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group controlId="formCoordinates">
                            <Form.Label className="text-dark">{t('Coordinates')}</Form.Label>
                            <div className="row">
                                {coordinatesLoading ?
                                    <Spinner as="span" className="center" animation="border" role="status" variant="secondary" />
                                    : // If loaded then draw coordinates
                                    <div>
                                        {coordinates && coordinates.longitude !== 0 && coordinates.latitude !== 0 ?
                                            <div>
                                                <Form.Label className="text-dark coordinates-label">
                                                    {t('LocationDefined')} <i>({coordinates.wgs.longitude},   {coordinates.wgs.latitude})</i>
                                                </Form.Label>
                                                <div className="altInputStyle">
                                                    {props.mapEvent ? "" :
                                                        <Button variant="danger" style={deleteRowStyle} onClick={(e) => {
                                                            e.preventDefault();
                                                            removeCoordinates();
                                                        }} type="text"><Icon icon={ICONS.DELETE} color='black' size='22' /></Button>
                                                    }
                                                </div>
                                            </div>
                                            : // If no coordinates then show button
                                            <div className="col-6">
                                                <Button type="text" variant="success" disabled={multiSelect || !observationRows.length ? true : false} onClick={(e) => {
                                                    e.preventDefault();

                                                    var observationsText = [];
                                                    observationRows.forEach(o => {
                                                        observationsText.push(o.productName);
                                                    });

                                                    setMapModalParameters(observationsText.join(', '));
                                                    setMapShow(true);
                                                }}>{t('DefineLocation')}</Button>
                                            </div>}
                                    </div>
                                }
                            </div>
                        </Form.Group>

                        {coordinates && coordinates.longitude !== 0 && coordinates.latitude !== 0 ?
                            <Form.Group controlId="showInAllYearsSwitch">
                                <div className="show-in-all-years-switch">
                                    <Form.Check // prettier-ignore
                                        type="switch"
                                        id="custom-switch"
                                        label={t('ShowInAllYears')}
                                        className="mobi-custom-switch"
                                        checked={visibility}
                                        onChange={(event) => {
                                            setObservationsToUpdate(!visibility);
                                            setVisibility(!visibility);
                                        }}
                                    />
                                </div>
                            </Form.Group>
                            : ''}

                        <Form.Group controlId="formPesticide">
                            <Form.Label className="text-dark">{t('PlantProtection_Pesticides')}</Form.Label>
                            {(pesticideRows) ? pesticideRows.map((p, i) =>
                                /* ----------- PESTICIDES ----------- */
                                <div key={i + p.pesticideId + p.product} >
                                    <div className={i > 0 ? "pesticide-selection-margin" : ""}>
                                        <Select
                                            className={validate && !pesticideRows[i].product ? `text-dark invalid-border` : `text-dark`}
                                            value={pesticides.length && pesticideRows[i]
                                                ? pesticides[1].options.find(v => {
                                                    if (v.value === pesticideRows[i].product) {
                                                        return v;
                                                    }
                                                }) : { label: (t('SelectProduct')), value: null }}
                                            onChange={(data) => {
                                                let newValue = pesticideRows[i];
                                                newValue.product = data.product;
                                                newValue.productName = data.productName;
                                                newValue.updated = true;
                                                newValue.unitText = data.unitText;
                                                newValue.unitType = data.unitType;

                                                // Update selected pesticide value
                                                let updatedSelections = updateArrayValue(i, newValue, pesticideRows);
                                                setPesticideRows(updatedSelections);
                                                setDisableSaveButton(false);
                                                setUpdatePP(Date.now());
                                            }}
                                            options={pesticides}
                                            formatGroupLabel={formatGroupLabel}
                                        />
                                    </div>
                                    <div className="altInputStyle">
                                        <Form.Label className="text-dark">{t('Amount_ha')}</Form.Label>
                                        <NumberFormat
                                            className={validate && pesticideRows[i].amount === 0 ? "form-control invalid-border" : "form-control"}
                                            value={pesticideRows[i].amount ? pesticideRows[i].amount : ''}
                                            placeholder={' ' + (pesticideRows[i].unitText ? '  ' + pesticideRows[i].unitText : '')}
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            onValueChange={(values) => {
                                                if (values.floatValue && round(values.floatValue) !== round(pesticideRows[i].amount)) {
                                                    let newRowData = pesticideRows[i];
                                                    newRowData.amount = values.floatValue;
                                                    if (values.floatValue) {

                                                        if (pesticideArea)
                                                            newRowData.amountArea = values.floatValue * pesticideArea;
                                                        else
                                                            newRowData.amountArea = 0;
                                                    }
                                                    else {
                                                        newRowData.amount = 0;
                                                        newRowData.amountArea = 0;
                                                    }
                                                    newRowData.updated = true;
                                                    let updatedRows = updateArrayValue(i, newRowData, pesticideRows);
                                                    setPesticideRows(updatedRows);
                                                    setDisableSaveButton(false);
                                                    setUpdatePP(Date.now());
                                                }
                                            }}
                                            displayType={'input'}
                                            thousandSeparator={' '}
                                            suffix={pesticideRows[i].unitText ? '  ' + pesticideRows[i].unitText : ''}
                                            allowNegative={false}
                                            isAllowed={valueMax}
                                            allowedDecimalSeparators={['.', ',']} />
                                        {/*<Form.Text className="text-muted" >*/}
                                        {/*    Määrä*/}
                                        {/*</Form.Text>*/}
                                    </div>
                                    <div className="altInputStyle">
                                        <Form.Label className="text-dark">{t('Amount_Area')}</Form.Label>
                                        <NumberFormat
                                            className="form-control"
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            displayType={'input'}
                                            thousandSeparator={' '}
                                            allowedDecimalSeparators={['.', ',']}
                                            suffix={pesticideRows[i].unitText ? '  ' + pesticideRows[i].unitText : ''}
                                            allowNegative={false}
                                            isAllowed={valueMax}

                                            value={(pesticideRows[i].amount * pesticideArea) ? pesticideRows[i].amount * pesticideArea : ''}
                                            placeholder={' ' + (pesticideRows[i].unitText ? '  ' + pesticideRows[i].unitText : '')}
                                            onValueChange={(values) => {
                                                if (values.floatValue && round(values.floatValue) !== round(pesticideRows[i].amountArea)) {
                                                    let newRowData = pesticideRows[i];
                                                    if (values.floatValue) {
                                                        if (pesticideArea > 0) {
                                                            newRowData.amount = values.floatValue / pesticideArea;
                                                        }
                                                        else
                                                            newRowData.amount = 0;

                                                        newRowData.amountArea = values.floatValue;
                                                    }
                                                    else {
                                                        newRowData.amount = 0;
                                                        newRowData.amountArea = 0;
                                                    }
                                                    newRowData.updated = true;
                                                    let updatedRows = updateArrayValue(i, newRowData, pesticideRows);
                                                    setPesticideRows(updatedRows);
                                                    setDisableSaveButton(false);
                                                    setUpdatePP(Date.now());
                                                }
                                            }}
                                        />

                                    </div>
                                    <div className="altInputStyle">
                                        {!p.insert
                                            ? <Button variant="danger" style={deleteRowStyle} onClick={(e) => {
                                                e.preventDefault();
                                                removePesticide(p, i);
                                            }} type="text"><Icon icon={ICONS.DELETE} color='black' size='22' /></Button>

                                            : <Button variant="danger" style={deleteRowStyle} onClick={(e) => {
                                                e.preventDefault();
                                                clearPesticideRow(p, i);
                                            }} type="text"> X </Button>}
                                    </div>
                                </div>
                            ) : ''
                            }
                            <div className="row">
                                <div className="col-6 pesticide-selection-margin">
                                    <Button
                                        variant="success"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            let latestElement = pesticideRows[pesticideRows.length - 1];
                                            if (!rowAdditionHandler(latestElement)) return;

                                            addEmptyPesticide();
                                        }} type="text">{t('PlantProtection_AddPesticide')}</Button>
                                </div>
                            </div>
                        </Form.Group>

                        <Form.Group controlId="formFertilize">
                            <Form.Label className="text-dark">{t('Fertilisers')}</Form.Label>
                            {(fertilizerRows) ? fertilizerRows.map((p, i) =>
                                /* ----------- FERTILIZERS ----------- */
                                <div key={i + p.pesticideId + p.product} >
                                    <div className={i > 0 ? "pesticide-selection-margin" : ""}>
                                        <Select
                                            className={validate && !fertilizerRows[i].product ? `text-dark invalid-border` : `text-dark`}
                                            value={fertilizers.length && fertilizerRows[i]
                                                ? fertilizers[1].options.find(v => v.value === fertilizerRows[i].product)
                                                : { label: (t('SelectFertilizer')), value: null }}
                                            onChange={(data) => {
                                                let newValue = fertilizerRows[i];
                                                newValue.product = data.product;
                                                newValue.productName = data.produtName;
                                                newValue.updated = true;
                                                newValue.unitText = data.unitText;
                                                newValue.unitType = data.unit;

                                                // Update fertilizer rows
                                                let updatedSelections = updateArrayValue(i, newValue, fertilizerRows);
                                                setFertilizerRows(updatedSelections);
                                                setDisableSaveButton(false);
                                                setUpdatePP(Date.now());
                                            }}
                                            options={fertilizers}
                                            formatGroupLabel={formatGroupLabel}
                                        />
                                    </div>
                                    <div className="altInputStyle">
                                        <Form.Label className="text-dark">{t('Amount_ha')}</Form.Label>
                                        <NumberFormat
                                            className={validate && fertilizerRows[i].amount === 0 ? "form-control invalid-border" : "form-control"}
                                            value={fertilizerRows[i].amount ? fertilizerRows[i].amount : ''}
                                            placeholder={' ' + (fertilizerRows[i].unitText ? '  ' + fertilizerRows[i].unitText : '')}
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            onValueChange={(values) => {
                                                if (values.floatValue && round(values.floatValue) !== round(fertilizerRows[i].amount)) {
                                                    let newRowData = fertilizerRows[i];
                                                    newRowData.amount = values.floatValue;
                                                    if (values.floatValue) {

                                                        if (pesticideArea)
                                                            newRowData.amountArea = values.floatValue * pesticideArea;
                                                        else
                                                            newRowData.amountArea = 0;
                                                    }
                                                    else {
                                                        newRowData.amount = 0;
                                                        newRowData.amountArea = 0;
                                                    }
                                                    newRowData.updated = true;
                                                    let updatedRows = updateArrayValue(i, newRowData, fertilizerRows);
                                                    setFertilizerRows(updatedRows);
                                                    setDisableSaveButton(false);
                                                    setUpdatePP(Date.now());
                                                }
                                            }}
                                            displayType={'input'}
                                            thousandSeparator={' '}
                                            suffix={fertilizerRows[i].unitText ? '  ' + fertilizerRows[i].unitText : ''}
                                            allowNegative={false}
                                            isAllowed={valueMax}
                                            allowedDecimalSeparators={['.', ',']} />

                                    </div>
                                    <div className="altInputStyle">
                                        <Form.Label className="text-dark">{t('Amount_Area')}</Form.Label>
                                        <NumberFormat
                                            value={(fertilizerRows[i].amount * pesticideArea) > 0 ? fertilizerRows[i].amount * pesticideArea : ''}
                                            placeholder={' ' + (fertilizerRows[i].unitText ? '  ' + fertilizerRows[i].unitText : '')}
                                            onValueChange={(values) => {
                                                if (values.floatValue && round(values.floatValue) !== round(fertilizerRows[i].amountArea)) {
                                                    let newRowData = fertilizerRows[i];
                                                    if (values.floatValue) {
                                                        if (pesticideArea > 0) {
                                                            newRowData.amount = values.floatValue / pesticideArea;
                                                        }
                                                        else
                                                            newRowData.amount = 0;

                                                        newRowData.amountArea = values.floatValue;
                                                    }
                                                    else {
                                                        newRowData.amount = 0;
                                                        newRowData.amountArea = 0;
                                                    }
                                                    newRowData.updated = true;
                                                    let updatedRows = updateArrayValue(i, newRowData, fertilizerRows);
                                                    setFertilizerRows(updatedRows);
                                                    setDisableSaveButton(false);
                                                    setUpdatePP(Date.now());
                                                }
                                            }}



                                            className="form-control"
                                            decimalScale={2}
                                            fixedDecimalScale={false}
                                            displayType={'input'}
                                            thousandSeparator={' '}
                                            suffix={fertilizerRows[i].unitText ? '  ' + fertilizerRows[i].unitText : ''}
                                            allowNegative={false}
                                            isAllowed={valueMax}
                                            allowedDecimalSeparators={['.', ',']}
                                        />
                                    </div>
                                    <div className="altInputStyle">
                                        {!p.insert
                                            ? <Button variant="danger" style={deleteRowStyle} onClick={(e) => {
                                                e.preventDefault();
                                                removeFertilizer(p, i);
                                            }} type="text"><Icon icon={ICONS.DELETE} color='black' size='22' /></Button>

                                            : <Button variant="danger" style={deleteRowStyle} onClick={(e) => {
                                                e.preventDefault();
                                                clearFertilizerRow(p, i);
                                            }} type="text"> X </Button>}
                                    </div>
                                </div>
                            ) : ''
                            }
                            <div className="row">
                                <div className="col-6 pesticide-selection-margin">
                                    <Button
                                        variant="success"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            let latestElement = fertilizerRows[fertilizerRows.length - 1];
                                            if (!rowAdditionHandler(latestElement)) return;

                                            addEmptyFertilizer();
                                        }} type="text">{t('Fertilization_AddFertiliser')}</Button>
                                </div>
                            </div>
                        </Form.Group>

                        <Accordion className="pesticide-selection-margin">
                            <Accordion.Item eventKey="1" className="extra-info-accordion-header">
                                <Accordion.Header>{t('Footnote')}</Accordion.Header>
                                <Accordion.Body>
                                    {!multiSelect ?
                                        <Form.Group controlId="formSprayArea">
                                            <Form.Label className="text-dark">{t('SprayArea')}</Form.Label>
                                            <NumberFormat
                                                className={validate && plotArea && plotArea < pesticideArea ? `form-control invalid-border` : `form-control`}
                                                displayType={'input'}
                                                allowNegative={false}
                                                placeholder={plotArea ? plotArea : '0'}
                                                value={pesticideArea}
                                                onChange={value => {
                                                    if (value.target.value) {
                                                        setPesticideArea(parseFloat(value.target.value));
                                                        growthUpdate();
                                                        updateFertilizingAreas(parseFloat(value.target.value));
                                                    }
                                                }}
                                                isAllowed={(values) => {
                                                    const { value } = values;
                                                    /* Max is selected plot current area?
                                                    if (selectedPlot.current) {
                                                        if (value < selectedPlot.current.area) return true;
                                                    }
                                                    */
                                                    if (value < 10000) return true;
                                                    return false;
                                                }}
                                            />
                                        </Form.Group>
                                        : ''}
                                    <Form.Group controlId="formWater">
                                        <Form.Label className="text-dark">{t('WaterLha')}</Form.Label>
                                        <NumberFormat
                                            className="form-control"
                                            placeholder='0'
                                            value={waterLha ? waterLha : ''}
                                            onChange={value => {
                                                if (value.target.value) {
                                                    setWaterLha(parseFloat(value.target.value));
                                                    growthUpdate();                                                   
                                                }
                                            }}
                                            displayType={'input'}
                                            allowNegative={false}
                                            isAllowed={(values) => {
                                                const { value } = values;
                                                if (value < 100000) return true;
                                                return false;
                                            }}
                                        />
                                    </Form.Group>
                                    {(gardenPlant) ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    <Form.Label className="text-dark">{t('StartTime')}</Form.Label>
                                                    <NumberFormat
                                                        className="form-control"
                                                        placeholder='0'
                                                        value={startTime ? startTime : ''}
                                                        onChange={value => {
                                                            if (value.target.value) {
                                                                setStartTime(parseFloat(value.target.value));
                                                                growthUpdate()                                                                
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        allowNegative={false}
                                                        isAllowed={(values) => {
                                                            const { value } = values;
                                                            if (value <= 24) return true;
                                                            return false;
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label className="text-dark">{t('Temperature')}</Form.Label>
                                                    <NumberFormat
                                                        className="form-control"
                                                        value={temperature ? temperature : ''}
                                                        decimalScale={1}
                                                        fixedDecimalScale={false}
                                                        onChange={value => {
                                                            if (value.target.value) {
                                                                setTemperature(parseFloat(value.target.value));
                                                                growthUpdate()                                                                
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        allowNegative={false}
                                                        isAllowed={(values) => {
                                                            const { value } = values;
                                                            if (value < 100) return true;
                                                            return false;
                                                        }}
                                                        allowedDecimalSeparators={['.', ',']}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className="text-dark">{t('WindMs')}</Form.Label>
                                                    <NumberFormat
                                                        className="form-control"
                                                        placeholder='0.0'
                                                        value={wind ? wind : ''}
                                                        decimalScale={1}
                                                        fixedDecimalScale={false}
                                                        onChange={value => {
                                                            if (value.target.value) {
                                                                setWind(parseFloat(value.target.value));
                                                                growthUpdate()                                                                
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        allowNegative={false}
                                                        isAllowed={(values) => {
                                                            const { value } = values;
                                                            if (value < 1000) return true;
                                                            return false;
                                                        }}
                                                        allowedDecimalSeparators={['.', ',']}
                                                    />
                                                </Col>
                                                <Col>
                                                    <Form.Label className="text-dark">{t('WindDirection')}</Form.Label>
                                                    <Select className="text-dark selecti" id="grouped-select" label="Grouping"
                                                        value={windDirection ? windDirection : { label: textt, value: null }}
                                                        onChange={(data) => { setWindDirection(data); growthUpdate(); }}
                                                        options={windDirectionList}
                                                    />
                                                </Col>
                                            </Row>
                                            <Form.Label className="text-dark">{t('ProtectionDistances')}:</Form.Label>
                                            <Row>
                                                <Col sm={{ span: 4, offset: 1 }}>
                                                    <Form.Label className="text-dark">{t('SafeDistanceWind')}</Form.Label>
                                                    <NumberFormat
                                                        className="form-control"
                                                        value={safeDistanceWind ? safeDistanceWind : ''}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        onChange={value => {
                                                            if (value.target.value) {
                                                                setSafeDistanceWind(parseFloat(value.target.value));
                                                                growthUpdate()                                                                
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        allowNegative={false}
                                                        isAllowed={(values) => {
                                                            const { value } = values;
                                                            if (value < 1000) return true;
                                                            return false;
                                                        }}
                                                        allowedDecimalSeparators={['.', ',']}
                                                    />
                                                </Col>
                                                <Col sm={{ span: 5, offset: 2 }}>
                                                    <Form.Label className="text-dark">{t('SafeDistanceWindTarget')}</Form.Label>
                                                    <Form.Control type="text" onChange={(event) => {
                                                        setSafeDistanceWindTarget(event.target.value);
                                                        growthUpdate();                                                        
                                                    }}
                                                        value={safeDistanceWindTarget ? safeDistanceWindTarget : ''} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col sm={{ span: 4, offset: 1 }}>
                                                    <Form.Label className="text-dark">{t('SafeDistanceSoil')}</Form.Label>
                                                    <NumberFormat
                                                        className="form-control"
                                                        value={safeDistanceSoil ? safeDistanceSoil : ''}
                                                        decimalScale={2}
                                                        fixedDecimalScale={false}
                                                        onChange={value => {
                                                            if (value.target.value) {
                                                                setSafeDistanceSoil(parseFloat(value.target.value));
                                                                growthUpdate()                                                                
                                                            }
                                                        }}
                                                        displayType={'input'}
                                                        allowNegative={false}
                                                        isAllowed={(values) => {
                                                            const { value } = values;
                                                            if (value < 1000) return true;
                                                            return false;
                                                        }}
                                                        allowedDecimalSeparators={['.', ',']}
                                                    />
                                                </Col>
                                                <Col sm={{ span: 5, offset: 2 }}>
                                                    <Form.Label className="text-dark">{t('SafeDistanceSoilTarget')}</Form.Label>
                                                    <Form.Control type="text" onChange={(event) => {
                                                        setSafeDistanceSoilTarget(event.target.value);
                                                        growthUpdate();                                                        
                                                    }}
                                                        value={safeDistanceSoilTarget ? safeDistanceSoilTarget : ''} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className="text-dark">{t('SprayBoom')}</Form.Label>
                                                    <Form.Control type="text" onChange={(event) => {
                                                        setSprayBoom(event.target.value);
                                                        growthUpdate();                                                        
                                                    }}
                                                        value={sprayBoom ? sprayBoom : ''} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className="text-dark">{t('ServiceLocation')}</Form.Label>
                                                    <Form.Control type="text" onChange={(event) => {
                                                        setServiceLocation(event.target.value);
                                                        growthUpdate();                                                        
                                                    }}
                                                        value={serviceLocation ? serviceLocation : ''} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Label className="text-dark">{t('Maker')}</Form.Label>
                                                    <Form.Control type="text" onChange={(event) => {
                                                        setMaker(event.target.value);
                                                        growthUpdate();                                                        
                                                    }}
                                                        value={maker ? maker : ''} />
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        <div>
                                            <Form.Group controlId="formBeginTime">
                                                <Form.Label className="text-dark">{t('StartTime')}</Form.Label>
                                                <NumberFormat
                                                    className="form-control"
                                                    placeholder='0'
                                                    value={startTime ? startTime : ''}
                                                    onChange={value => {
                                                        if (value.target.value) {
                                                            setStartTime(parseFloat(value.target.value));
                                                            growthUpdate()                                                           
                                                        }
                                                    }}
                                                    displayType={'input'}
                                                    allowNegative={false}
                                                    isAllowed={(values) => {
                                                        const { value } = values;
                                                        if (value <= 24) return true;
                                                        return false;
                                                    }}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formWind">
                                                <Form.Label className="text-dark">{t('WindMs')}</Form.Label>
                                                <NumberFormat
                                                    className="form-control"
                                                    placeholder='0.0'
                                                    value={wind ? wind : ''}
                                                    decimalScale={1}
                                                    fixedDecimalScale={false}
                                                    onChange={value => {
                                                        if (value.target.value) {
                                                            setWind(parseFloat(value.target.value));
                                                            growthUpdate()                                                            
                                                        }
                                                    }}
                                                    displayType={'input'}
                                                    allowNegative={false}
                                                    isAllowed={(values) => {
                                                        const { value } = values;
                                                        if (value < 1000) return true;
                                                        return false;
                                                    }}
                                                    allowedDecimalSeparators={['.', ',']}
                                                />
                                            </Form.Group>
                                        </div>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Form.Group controlId="formDate">
                            <Form.Label className="text-dark">{t('Date')}</Form.Label>
                            <Form.Control type="text" onClick={() => setCalendarShow(true)} placeholder={t('Date')} value={dayJsFormatDate(date)} onChange={() => { }} />
                        </Form.Group>

                        {multiSelect ?
                            <Accordion className="show-chosen-plots-accordion">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><b>{t('ShowChosenPlots')}</b></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="plot-list">
                                            {props.selectEvent.selectedPlots.map((plot, i) =>
                                                <div key={i}>
                                                    <b>{plot.name} - {plot.apChar}</b> ({plot.area} ha)
                                                </div>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            : ''}
                    </Form>
                </Modal.Body>

                <Modal.Footer className="modal-footer-right-align">
                    {(props.selectEvent?.selectedElement || !props.mapEvent?.new) && buttonWriteShow === true ?
                        <Button variant='outline-danger' className="footer-button-left" onClick={() => deletePlantProtection()}>{t('Delete')}</Button>
                        : ''}

                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Cancel')}</Button>

                    {buttonWriteShow === true ?
                        <Button variant="success" className="btn-show-more" disabled={disableSaveButton} onClick={() => {
                            setValidation(true);

                            multiSelect ? saveMultiSelectPlantProtection() : savePlantProtection();
                        }}>{t('Save')}</Button>
                        : ''}
                </Modal.Footer>
            </Modal>
            <CalendarModal
                show={calendarShow}
                onHide={() => setCalendarShow(false)}
                onChange={updateGrowthInfoDate}
                selecteddate={date}
            />
            <MapModal
                show={mapShow}
                onHide={(point) => {
                    setMapShow(false);
                    if (point) {
                        setCoordinates(point.coord);
                        updateObservationCoordinates(point.coord);
                    }
                }}
                pointNote={mapModalParameters ? mapModalParameters : t('Observation')}
                featureType={"5304"}
            />
        </>
    );
}

export default PlantProtectionModal;
import React, { useState, useEffect, useContext, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import * as dayjs from 'dayjs'
import { useTranslation } from 'react-i18next';
import { postContentWithAction, putContentWithAction } from '../../apiConnect';
import { getSelectedLang } from '../../common';
import { formatPlannedDate, groupByKey, valueMax, dayJsFormatDate, groupByMarked, round, updateCalculativeArray } from '../../commonFunctions';
import NumberFormat from 'react-number-format';
import CalendarModal from './CalendarModal';
import Spinner from 'react-bootstrap/Spinner';
import { useAlert } from 'react-alert'
import AppContext from '../../AppContext';
import { ICONS } from '../../img/icons'
import Icon from '../../img/Icon'
import { OverlayTrigger, Tooltip, Badge } from 'react-bootstrap'

import '../../css/general.css'


const MarkFertilizerModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [plannedFerts, setPlannedFerts] = useState([]);
    const [combinedFerts, setCombinedFerts] = useState([]);
    const [calendarShow, setCalendarShow] = useState(false);
    const [date, setDate] = useState(dayjs().format());
    const [contentIsLoading, setContentIsLoading] = useState(true);
    const [infoText, setInfoText] = useState('Ladataan tietoja');

    //For area and amount calculations, CCD = CalculationCombinedData
    const [cCD, setCCD] = useState([]);

    const alert = useAlert();

    useEffect(() => {
        var plotIds = [];

        props.selectEvent.selectedPlots.forEach(p => {
            plotIds.push({ plotId: p.apNumber });
        });

        const promiseFert = new Promise((resolve, reject) => {
            postContentWithAction(auth.getAccessToken(), 'fertilize', 'getFertilizingsForPlots', {
                models: plotIds, lang: getSelectedLang()
            }, (data) => {
                resolve(data);
            }).catch(e => {
                reject(Error("Promise rejected"));
            });
        });

        const promiseSow = new Promise((resolve, reject) => {
            postContentWithAction(auth.getAccessToken(), 'sow', 'sowsForPlots', {
                models: plotIds, lang: getSelectedLang()
            }, (data) => {
                resolve(data);
            }).catch(e => {
                reject(Error("Promise rejected"));
            });
        });

        Promise.all([promiseFert, promiseSow]).then(([dataFert, dataSow]) => {

            dataFert = dataFert.filter((fert) => fert.date || fert.plannedDate);

            dataFert.forEach(fert => {
                fert.plotData = props.selectEvent.selectedPlots.find(p => p.apNumber === fert.plotId);
            });
            setPlannedFerts(dataFert);

            if (dataSow.length > 0) {
                dataSow = dataSow.filter((sow) => sow.sowingDate && sow.actionDate);
                if (dataSow.length > 0) {
                    dataFert.forEach(fert => {
                        if (fert.fertilizerKind === 1) {
                            const s = dataSow.find(p => p.plotId === fert.plotId);
                            if (s && s.actionDate === fert.plannedDate) {
                                fert.date = dayjs(s.sowingDate).format();
                            }
                        }
                    });
                }
            }

            dataFert.forEach(d => {
                d.productWithPesticide = d.pesticideId ? d.product + '_' + d.pesticideId : d.product;
            });

            var grouped1 = groupByKey(dataFert, 'productWithPesticide');
            var grouped2 = [];
            for (const key in grouped1) {
                var m = groupByMarked(grouped1[key], 'date', 'plannedDate');
                var b = [];
                for (const type in m) {
                    for (const key in m[type]) { // Looping grouped keys
                        var combinedIds = [];
                        var combinedArea = 0;
                        var combinedAmount = { min: m[type][key][0].amount, max: m[type][key][0].amount };

                        m[type][key].forEach(s => { // Looping ferts
                            combinedIds.push({
                                plotId: s.plotId,
                                fertilizingId: s.fertilizingId,
                                plotName: s.plotData.name + ' - ' + s.plotData.apChar,
                                plotArea: s.plotData.area
                            });
                            if (s.area)
                                combinedArea += s.area;
                            else
                                combinedArea += s.plotData.area;
                            combinedAmount.min = Math.min(combinedAmount.min, s.amount);
                            combinedAmount.max = Math.max(combinedAmount.max, s.amount);
                        });
                        var combinedData = { ...m[type][key][0] }; // Copying first element for base data

                        if (combinedAmount.min != combinedAmount.max) combinedData.amount = null;

                        b.push({
                            date: key,
                            combinedData: combinedData,
                            combinedArea: combinedArea,
                            combinedIds: combinedIds,
                            combinedAmount: combinedAmount
                        });
                    }
                }
                grouped2.push({
                    fertilizer: key,
                    objects: b
                });
            }

            setCCD(grouped2);
            setCombinedFerts(grouped2);

            setContentIsLoading(false);
            setInfoText('Ei suunniteltuja lannoitteita');
        }).catch(error => console.log(`Error in executing ${error}`));

    }, [props.selectEvent]);

    const updateDate = (value) => {
        var newDate = date;
        newDate.date = value;
        setDate(newDate);
    };

    const saveFertilizers = () => {
        let today = dayjs().format('YYYYMMDD');
        let invalidDate = false;
        var newFerts = [];

        combinedFerts.forEach(product => {
            product.objects.forEach(fert => {
                fert.combinedIds.forEach(plot => {
                    var originalData = plannedFerts.find(s => s.fertilizingId === plot.fertilizingId);
                    var data = fert.combinedData;
                    let formattedDate = dayjs(data.date).format('YYYYMMDD');
                    if (data.date && today < formattedDate) invalidDate = true; // Check for valid dates
                    if (data.date) {
                        newFerts.push({
                            plannedDate: fert.date,
                            amount: data.amount ? round(data.amount) : originalData.amount,
                            description: data.description,
                            product: data.product,
                            fertilizingId: plot.fertilizingId,
                            pesticideId: originalData.pesticideId,
                            plotId: plot.plotId,
                            date: data.date,
                            unitType: data.unitType,
                            area: originalData.area ? originalData.area : plot.plotArea
                        });
                    }
                });
            });
        });

        if (!invalidDate) {
            putContentWithAction(auth.getAccessToken(), 'fertilize', 'update', {
                models: newFerts
            }).then(() => {
                props.setRefresh(Date.now());
                alert.show(t('SavedSuccesfully'), { type: 'success' });
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
            });

            props.onHide();
        }
        else {
            alert('invalid date')
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title className="text-dark">{t('MarkFertilizerDone')}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-dark">
                    <Form>
                        {contentIsLoading ? <Spinner as="span" className="center" animation="border" role="status" variant="secondary" /> : ''}
                        {(combinedFerts) && (combinedFerts.length > 0) ? combinedFerts.map((product, i) =>
                            product.objects.map((fert, j) =>
                                <div key={i} id={i} className="mark-row-style border-bottom">
                                    <div className="mark-row-header">
                                        <b>{fert.combinedData.productName ? fert.combinedData.productName : fert.combinedData.description}</b>
                                        <br />
                                        {fert.combinedIds.length > 1 ?
                                            <OverlayTrigger trigger="click" rootClose placement="right" overlay={
                                                <Tooltip id="overlay">
                                                    {fert.combinedIds.slice(1).map((p, i) =>
                                                        <div>{p.plotName}</div>
                                                    )}
                                                </Tooltip>
                                            }>
                                                <Button size="sm" variant="light">
                                                    {fert.combinedIds[0].plotName} <b>+</b> <Badge pill bg="secondary">{fert.combinedIds.length - 1}</Badge>
                                                </Button>
                                            </OverlayTrigger>
                                            :
                                            fert.combinedIds.length === 1 ?
                                                <Button size="sm" variant="light" className="disabledButton">
                                                    {fert.combinedIds[0].plotName}
                                                </Button>
                                                : ''}
                                    </div>

                                    {(fert.combinedData.pesticideId) ?
                                        <div className="mark-row-content">
                                            <span>{t('TankMixture')} </span> <Icon icon={ICONS.INFO} color='#2ba3c4' size='20' />
                                        </div>
                                        : ''}

                                    <div className="mark-row-content">
                                        <Form.Group controlId="formAmount">
                                            <Form.Label className="text-dark">{t('Amount_ha')}</Form.Label>
                                            <NumberFormat
                                                className="form-control inline-form-field"
                                                value={cCD[i].objects[j].combinedData.amount}
                                                value={fert.combinedData.amount}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                placeholder={`${fert.combinedAmount.min} - ${fert.combinedAmount.max}`}
                                                onValueChange={(value) => {
                                                    if (value.floatValue && round(value.floatValue) !== round(cCD[i].objects[j].combinedData.amount)) {
                                                        if (value.floatValue) {
                                                            setCCD(updateCalculativeArray(cCD, value.floatValue, i, j));
                                                            fert.combinedData.amount = value.floatValue;
                                                        }
                                                    }
                                                }}
                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                suffix={'  ' + fert.combinedData.unitText}
                                                isAllowed={valueMax}
                                                allowNegative={false}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group>
                                        <Form.Group controlId="formAmountHa">
                                            <Form.Label className="text-dark">{t('Amount_Area')}</Form.Label>
                                            <NumberFormat
                                                className="form-control inline-form-field"
                                                value={cCD[i].objects[j].combinedData ? cCD[i].objects[j].combinedData.amount * cCD[i].objects[j].combinedArea : 0}
                                                decimalScale={2}
                                                fixedDecimalScale={false}
                                                onValueChange={(value) => {
                                                    if (value.floatValue && round(value.floatValue) !== round(cCD[i].objects[j].combinedData.amount * cCD[i].objects[j].combinedArea)) {
                                                        if (value.floatValue) {
                                                            if (cCD[i].objects[j].combinedArea > 0) {
                                                                setCCD(updateCalculativeArray(cCD, (value.floatValue / cCD[i].objects[j].combinedArea), i, j));
                                                                fert.combinedData.amount = value.floatValue / fert.combinedArea;
                                                            }
                                                        }
                                                    }
                                                }}
                                                displayType={'input'}
                                                thousandSeparator={' '}
                                                suffix={'  ' + fert.combinedData.unitText}
                                                isAllowed={valueMax}
                                                allowNegative={false}
                                                allowedDecimalSeparators={['.', ',']} />
                                        </Form.Group>
                                        <Form.Group controlId="fromDate">
                                            <Form.Label className="text-dark">{t('Date')}</Form.Label>
                                            <Form.Control className="form-control inline-form-field" type="text" onClick={() => {
                                                setDate(fert.combinedData);
                                                setCalendarShow(true);
                                            }}
                                                placeholder={formatPlannedDate(fert.combinedData.plannedDate, t)}
                                                value={fert.combinedData.date ? dayJsFormatDate(fert.combinedData.date) : ''}
                                                onChange={() => { }} />
                                        </Form.Group>
                                    </div>
                                </div>)
                        ) : <div>{infoText}</div>}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Cancel')}</Button>
                    <Button variant="success" className="btn-show-more" onClick={() => { saveFertilizers(); }}>{t('Save')}</Button>
                </Modal.Footer>
            </Modal>
            {calendarShow === true ?
                <CalendarModal
                    show={calendarShow}
                    onHide={() => setCalendarShow(false)}
                    onChange={updateDate}
                    selecteddate={date.date}
                />
                : ''}
        </>
    )
}

export default MarkFertilizerModal;
import React, { useState, useRef, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';
import * as dayjs from 'dayjs'

import CalendarModal from './CalendarModal';
import { getContentWithAction, postContentWithAction, putContentWithAction } from '../../apiConnect';
import AppContext from '../../AppContext';
import { getSelectedPlot, getSelectedLang, customStyles, formatGroupLabel } from '../../common';
import { useAlert } from 'react-alert'

import { valueMax, dayJsFormatDate, round } from '../../commonFunctions';

import '../../css/general.css'
import '../../css/plotOperation.css'

const SowModal = (props) => {
    const { t } = useTranslation();
    const { auth } = useContext(AppContext);

    const [calendarShow, setCalendarShow] = useState(false);
    const [varieties, setVarieties] = useState([]);
    const [selectedVariety, setSelectedVariety] = useState(null);
    const [amount, setAmount] = useState(0);
    const [amountArea, setAmountArea] = useState(0);
    const [totalArea, setTotalArea] = useState(0);
    const [date, setDate] = useState(dayjs().format());
    const [plantName, setPlantName] = useState('');
    const [multiSelect, setMultiSelect] = useState(false);
    const [varietyUnit, setVarietyUnit] = useState('kg');
    const [validate, setValidation] = useState(false);
    const [buttonWriteShow, setButtonWriteShow] = useState(false); // Example of allowed save btn disabled
    const textt = (t('ChooseVariety'));

    const alert = useAlert();

    let selectedPlot = useRef(getSelectedPlot());

    const responseHandler = (data) => {
        if (!data) return;
        setSelectedVariety({ label: data.description, value: data.varietyId, isMixture: data.mixture === 1 ? true : undefined });
        setAmount(data.amount);

        if (data.amount && selectedPlot && selectedPlot.current && selectedPlot.current.area)
            setAmountArea(data.amount * selectedPlot.current.area);

        if (data.unitNumber)
            setVarietyUnit(data.unitText || 'kg');

        if (data.sowingDate) {
            setDate(dayjs(data.sowingDate).format());
        }
    }

    useEffect(() => {
        getContentWithAction(auth.getAccessToken(), 'session', 'storeAllowed', {}).then((result) => {
            setButtonWriteShow(result);
        });

        if (props.selectEvent.selectedPlots) { // If multiple chosen plots
            setMultiSelect(true);
            setPlantName(props.selectEvent.selectedPlots[0].plantName);
            fetchVarietyData(props.selectEvent.selectedPlots[0].plantId, getSelectedLang()).then(data => {
                formatVarietyGroups(data);
            });
            let total = props.selectEvent.selectedPlots.reduce((total, currentItem) => total = total + currentItem.area, 0);
            setTotalArea(total);
        }
        else { // Only one selected plot
            setPlantName(selectedPlot.current.plantName);
            fetchVarietyData(selectedPlot.current.plantId, getSelectedLang()).then(data => {
                formatVarietyGroups(data);
            });
        }

        if (props.selectEvent.selectedElement) { // Editing
            setValidation(true);
            responseHandler(props.selectEvent.selectedElement);
        }
        else { // Adding new
            responseHandler({
                varietyId: 0,
                amount: 0,
                sowingDate: dayjs().format()
            });
        }
    }, [props.selectEvent]);

    let fetchVarietyData = (id, selectedLand) => {
        return Promise.all([
            getContentWithAction(auth.getAccessToken(), 'resource', 'previousVariety', { plantId: id, lang: selectedLand }),
            getContentWithAction(auth.getAccessToken(), 'resource', 'variety', { plantId: id, lang: selectedLand })
        ]).then(([previousVarieties, varieties]) => {
            return { previousVarieties, varieties };
        });
    }

    const formatVarietyGroups = (data) => {
        let groupsList = [
            { label: t('Previously_Used'), options: data.previousVarieties }
        ];
        for (var v in data.varieties) {
            let tempList = [];
            for (var p in data.varieties[v].groupVarieties) {
                let vary = data.varieties[v].groupVarieties[p];
                let obj2 = { label: vary.label, value: vary.value, unitText: vary.unitText, isMixture: vary.isMixture };
                tempList.push(obj2);
            }
            groupsList = [
                ...groupsList,
                { label: data.varieties[v].groupName, options: tempList }
            ];
        }
        setVarieties([...groupsList]);
    };


    const deleteSow = () => {
        postContentWithAction(auth.getAccessToken(), 'sow', 'delete', { number: props.selectEvent.selectedElement.number, plotId: selectedPlot.current.apNumber }, () => {
            props.onHide();
            props.setRefresh(Date.now());
        });
    }

    const saveSow = () => {

        //validate
        if (!amount) return;
        if (!selectedVariety.value) return;

        var newSows = [];

        if (multiSelect) {
            props.selectEvent.selectedPlots.forEach((plot) => {
                newSows.push({
                    plotId: plot.apNumber,
                    number: 0,
                    amount: round(amount),
                    varietyId: selectedVariety.value,
                    sowingDate: date,
                    mixture: selectedVariety.isMixture ? 1 : 0
                });
            });

            postContentWithAction(auth.getAccessToken(), 'sow', 'new', {
                models: newSows
            }, (data) => {
                props.setRefresh(Date.now());
                alert.show(t('SavedSuccesfully'), { type: 'success' });
            }).catch(e => {
                alert.show(t('SavedFailed'), { type: 'error' });
            });
        }
        else {
            if (!props.selectEvent.selectedElement) {
                newSows.push({
                    plotId: selectedPlot.current.apNumber,
                    number: 0,
                    amount: round(amount),
                    varietyId: selectedVariety.value,
                    sowingDate: date,
                    mixture: selectedVariety.isMixture ? 1 : 0
                });

                postContentWithAction(auth.getAccessToken(), 'sow', 'new', {
                    models: newSows
                }, (data) => {
                    props.setRefresh(Date.now());
                    alert.show(t('SavedSuccesfully'), { type: 'success' });
                }).catch(e => {
                    alert.show(t('SavedFailed'), { type: 'error' });
                });
            }
            else {
                var updateSows = [];
                updateSows.push({
                    plotId: selectedPlot.current.apNumber,
                    number: props.selectEvent.selectedElement.number,
                    amount: round(amount),
                    varietyId: selectedVariety.value,
                    sowingDate: date,
                    mixture: selectedVariety.isMixture ? 1 : 0
                });
                putContentWithAction(auth.getAccessToken(), 'sow', 'update', {
                    models: updateSows
                }).then((data) => {
                    props.setRefresh(Date.now());
                    alert.show(t('SavedSuccesfully'), { type: 'success' });
                }).catch(e => {
                    alert.show(t('SavedFailed'), { type: 'error' });
                });
            }
        }
        props.onHide();
    }

    let varietyChanged = (data) => {
        setSelectedVariety(data);
        setVarietyUnit(data.unitText || 'kg');
    }

    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton >
                    <Modal.Title className="text-dark">{t('Sowing')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formPlant">
                            <Form.Label className="text-dark">{t('Plant')}</Form.Label>
                            <Form.Control type="text" value={plantName} readOnly />
                        </Form.Group>
                        <Form.Group controlId="formVariety">
                            <Form.Label className="text-dark">{t('Sowing_Variety_Code')}</Form.Label>
                            <Select
                                className={validate && !selectedVariety.value ? "text-dark invalid-border" : "text-dark"}
                                value={selectedVariety?.label ? selectedVariety : { label: textt, value: null }}
                                onChange={(data) => varietyChanged(data)}
                                options={varieties}
                                formatGroupLabel={formatGroupLabel}
                                styles={customStyles}
                            />
                        </Form.Group>
                        <Form.Group controlId="formAmount">
                            <Form.Label className="text-dark">{t('Amount_ha')}</Form.Label>
                            <NumberFormat
                                className={validate && amount === 0 ? "form-control invalid-border" : "form-control"}
                                value={amount > 0 ? amount : ''}
                                placeholder={' ' + varietyUnit}
                                decimalScale={2}
                                fixedDecimalScale={false}
                                onValueChange={(values) => {
                                    if (values.floatValue && round(values.floatValue) !== round(amount)) {
                                        if (values.floatValue) {
                                            setAmount(values.floatValue)

                                            if (!multiSelect) {
                                                if (selectedPlot.current && selectedPlot.current.area)
                                                    setAmountArea(values.floatValue * selectedPlot.current.area);
                                                else
                                                    setAmountArea(0);
                                            }
                                            else {
                                                if (totalArea)
                                                    setAmountArea(values.floatValue * totalArea);
                                                else
                                                    setAmountArea(0);
                                            }
                                        }
                                        else {
                                            setAmount(0);
                                            setAmountArea(0);
                                        }
                                    }
                                }}
                                displayType={'input'}
                                thousandSeparator={' '}
                                allowedDecimalSeparators={['.', ',']}
                                suffix={'  ' + varietyUnit}
                                allowNegative={false}
                                isAllowed={valueMax}
                            />
                        </Form.Group>
                        {!multiSelect ?
                            <Form.Group controlId="formAmountHa">
                                <Form.Label className="text-dark">{t('Amount_Area')}</Form.Label>
                                <NumberFormat
                                    className="form-control"
                                    value={amountArea > 0 ? amountArea : ''}
                                    placeholder={' ' + varietyUnit}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    onValueChange={(values) => {
                                        if (values.floatValue && round(values.floatValue) !== round(amountArea)) {
                                            if (values.floatValue) {
                                                if (selectedPlot.current.area > 0) {
                                                    setAmount(values.floatValue / selectedPlot.current.area);
                                                }
                                                else
                                                    setAmount(0);

                                                setAmountArea(values.floatValue);
                                            }
                                            else {
                                                setAmount(0);
                                                setAmountArea(0);
                                            }
                                        }
                                    }}
                                    displayType={'input'}
                                    thousandSeparator={' '}
                                    allowedDecimalSeparators={['.', ',']}
                                    suffix={'  ' + varietyUnit}
                                    allowNegative={false}
                                    isAllowed={valueMax}
                                />
                            </Form.Group>
                            :
                            <Form.Group controlId="formAmountHa">
                                <Form.Label className="text-dark">{t('Amount_Area')}</Form.Label>
                                <NumberFormat
                                    className="form-control"
                                    value={amountArea > 0 ? amountArea : ''}
                                    placeholder={' ' + varietyUnit}
                                    decimalScale={2}
                                    fixedDecimalScale={false}
                                    onValueChange={(values, e) => {
                                        if (values.floatValue && round(values.floatValue) !== round(amountArea)) {
                                            if (values.floatValue) {
                                                if (totalArea > 0) {
                                                    setAmount(values.floatValue / totalArea);
                                                }
                                                else
                                                    setAmount(0);

                                                setAmountArea(values.floatValue);
                                            }
                                            else {
                                                setAmount(0);
                                                setAmountArea(0);
                                            }
                                        }
                                    }}
                                    displayType={'input'}
                                    thousandSeparator={' '}
                                    allowedDecimalSeparators={['.', ',']}
                                    suffix={'  ' + varietyUnit}
                                    isAllowed={valueMax}
                                />
                            </Form.Group>
                        }
                        <Form.Group controlId="fromDate">
                            <Form.Label className="text-dark">{t('Date')}</Form.Label>
                            <Form.Control type="text" onClick={() => setCalendarShow(true)} placeholder={t('Date')} value={dayJsFormatDate(date)} onChange={() => { }} />
                        </Form.Group>
                        {multiSelect ?
                            <Accordion className="show-chosen-plots-accordion">
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header><b>{t('ShowChosenPlots')}</b></Accordion.Header>
                                    <Accordion.Body>
                                        <div className="plot-list">
                                            {props.selectEvent.selectedPlots.map((plot, i) =>
                                                <div key={i}>
                                                    <b>{plot.name} - {plot.apChar}</b> ({plot.area} ha)
                                                </div>
                                            )}
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            : ''}
                    </Form>
                </Modal.Body>
                <Modal.Footer className="modal-footer-right-align">

                    {/*btns to left*/}
                    {props.selectEvent.selectedElement && buttonWriteShow === true ?
                        <Button variant='outline-danger' className="footer-button-left"
                            onClick={() => deleteSow()}>{t('Delete')}</Button> : ''}

                    {/*btns to right*/}
                    <Button variant="outline-secondary" onClick={props.onHide}>{t('Cancel')}</Button>

                    {buttonWriteShow === true ?
                        <Button variant="success" className="btn-show-more" onClick={() => {
                            setValidation(true);
                            saveSow();
                        }}>{t('Save')}</Button>
                        : ''}
                </Modal.Footer>
            </Modal>
            <CalendarModal
                show={calendarShow}
                onHide={() => setCalendarShow(false)}
                onChange={setDate}
                selecteddate={date}
            />
        </>
    );
}

export default SowModal;